import React from "react";
import { Button, Form, Input, Radio, Row, Col } from "antd";
import MaskedInput from "antd-mask-input";
import { useState, useEffect } from "react";
// import capa from './../capa.png'

const FormPage = ({ corBg, corTexto, path, festa, enviarConfirmacao }) => {
  const [state, setState] = useState({
    card: "5555444433332222",
  });
  const [unmaskedValue, setUnmaskedValue] = useState();

  const onChange = (e) => {
    setState({ [e.target.name]: e.target.value });
    setUnmaskedValue(e.unmaskedValue);
  };

  const onFinish = async (values) => {
    values.user.Whatsapp = unmaskedValue;
    await enviarConfirmacao(values);
    form.resetFields();
  };

  const style = {
      paddingRight: '20px',
      margin: "0 auto",
      align:"middle",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      // '@media (max-width: 600px)': {
      //   height: "0vh",
      // },
    
  }

  const [form] = Form.useForm();

  useEffect(() => {
    document.body.style.backgroundColor = "#F5F7F9"
    document.title = "PUZLI | Confirmação de presença - " + festa.Festa;
  }, [window.location.pathname]);

  return (
    
    <>
    <Row justify="center">
      <Col span={20} className="wrap" style={{backgroundColor: corBg}}>
      <div style={{backgroundImage:`url(${festa.Capa})`, height: '400px', width:'100%', backgroundPositionX:'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}></div>
    <Col span={20} style={{maxWidth: '400px'}}>
      <Form
      className="formPage"
        style={style}
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
      >
        <Form.Item
          style={{ color: corTexto}}
          name={["user", "Nome"]}
          label={<label style={{ color: corTexto }}>Nome</label>}
          rules={[
            {
              required: true,
              message: "Informe seu nome",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ color: corTexto, margin: '0px !important' }}
          name={["user", "Whatsapp"]}
          label={<label style={{ color: corTexto }}>Whatsapp</label>}
          rules={[
            {
              required: true,
              message: "Informe seu Whatsapp",
            },
          ]}
        >
          <MaskedInput
            value={state.card}
            mask="(00) 0 0000-0000"
            name="card"
            size="20"
            onChange={(e) => onChange(e)}
          />
        </Form.Item>
        <Form.Item
          name={["user", "Confirma"]}
          // labelCol={{
          //   span: ,
          // }}
          label={
            <label style={{ color: corTexto }}>Confirmação de presença</label>
          }
          rules={[
            {
              required: true,
              message: "Por favor, selecione uma opção.",
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            className="confirma-wrapper"
          >
            <Radio.Button
            className="sim"
              value="true"
              style={{textAlign: "center", marginRight: '40px', width: '120px'}}
            >
              Sim!
            </Radio.Button>
            <Radio.Button
            className="nao"
              value="false"
              style={{ textAlign: "center", width: '120px' }}
            >
              Não.
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item style={{marginTop: '50px'}}>
          <Button style={{height: '40px', borderRadius: '8px'}}
          type="primary" htmlType="submit" block
          > 
            Enviar
          </Button>
        </Form.Item>
      </Form>
      </Col>
      </Col>
      </Row>
    </>
  );
};

export default FormPage;
