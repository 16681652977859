import React from 'react'
import './app.css'
import CadastroConvidado from './Components/CadastroConvidado';
import { Outlet } from "react-router-dom";

export const App = () => { 
  
  return (
    <>
    <CadastroConvidado/>
    <Outlet/>
    </>
  )
}
