import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/css/antd.css";
import { App } from "./App";
import { BrowserRouter, Route, Routes, Navigate  } from "react-router-dom";
import Agradecimento from "./Components/Agradecimento/Agradecimento";

const root = ReactDOM.createRoot(document.getElementById("root"));
// const App = React.lazy(() => './App')
root.render(
  <BrowserRouter>
    <Fragment>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/ok" element={<Agradecimento />}/>
        <Route exact path="*" element={<App />} />
      </Routes>
    </Fragment>
  </BrowserRouter>
);
