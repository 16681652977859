import React from "react";
import { useEffect } from "react";
import './noparty.css';
import Logo from './marca22-022.png';
import Logo2 from './logo2.png';

const NoParty = () => {
 
  useEffect(() => {
          changeClass(); 
          document.title = "PUZLI | Ooops! Página incorreta"
  }, []);

  const changeClass = () => {
    document.querySelector(".cont_principal").className =
    "cont_principal cont_error_active";
  }

  return (
    <div className="cont_principal">
      <div className="cont_error">
        <h1>OOPS</h1>
        <p>Tá perdido? Não tem nada aqui não.</p>
      </div>
      <div className="logo-puzli"
      style={{background: `url(${Logo}) left top/auto repeat local`}}
      ></div>
        <div className="logo-puzli2"
      style={{background: `url(${Logo2}) left top/auto repeat local`}}
      ></div>
      
      <div className="cont_aura_1"></div>
      <div className="cont_aura_2"></div>
    </div>
  );
};

export default NoParty;
