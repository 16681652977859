import React, { useEffect, useState } from "react";
import ConsultaConvidados from "./ConsultaConvidados";
import { initializeApp } from "firebase/app";
import { addDoc, collection, getDocs, getFirestore } from "firebase/firestore";
import NoParty from "./NoParty/NoParty";
import LoadingPage from "./LoadingPage/LoadingPage";
import Swal from "sweetalert2";

initializeApp({
  apiKey: "AIzaSyDq5IcSQ-qs5Xx9ubgzDgTEgsbNGJgNXxk",
  authDomain: "puzli-f557d.firebaseapp.com",
  projectId: "puzli-f557d",
  storageBucket: "puzli-f557d.appspot.com",
  messagingSenderId: "710360760281",
  appId: "1:710360760281:web:1d848e4df49bb642a4e3c8",
  measurementId: "G-JNXPJ6LB6G",
});

const CadastroConvidado = () => {
  const [convidado, setConvidado] = useState("");
  const [festa, setFesta] = useState("");
  const [loading, setLoading] = useState(true);
  const [path, setPath] = useState(window.location.pathname.replace("/", ""));

  const db = getFirestore();
  const collectionConvidados = collection(db, "Presenca");
  const collectionFestas = collection(db, "Festa");

  const enviarConfirmacao = async (data) => {
    data.user.CodigoEvento = path;

    var dados = data.user;
    var currentDate = new Date();
    dados.Data = currentDate;
    await addDoc(collectionConvidados, {
      dados,
    }).then(() => {
      Swal.fire({
        icon: "success",
        title: "Tudo certo!",
        text: "Cadastro realizado!",
      }).then(() => {window.location = '/ok'});
    });
  };

  const getConvidados = async () => {
    const data = await getDocs(collectionConvidados);
    const dados = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setPath(window.location.pathname.toUpperCase().replace("/", ""));
    setConvidado(dados.filter((x) => x.CodigoEvento === path.toUpperCase()));
    getBackgroundColor();
    setLoading(false);
  };

  const getFesta = async () => {
    const data = await getDocs(collectionFestas);
    const dados = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setPath(window.location.pathname.replace("/", "").toUpperCase());
    setFesta(dados.find((x) => x.CodigoEvento === path.toUpperCase()));
    getConvidados();
  };

  const getBackgroundColor = () => {
    festa
      ? (document.body.style.backgroundColor = "#F5F7F9")
      : (document.body.style.backgroundColor = "#D4D9ED");
  };

  useEffect(() => {
    const vari = window.location.pathname.replace("/", "").toUpperCase();
    setPath(vari);
    getFesta();
  }, [window.location.pathname]);

  return loading == true ? (
    <LoadingPage />
  ) : festa ? (
    <ConsultaConvidados
      setConvidado={setConvidado}
      convidado={convidado}
      corBg={festa.Cor ? festa.Cor : "#F1F1F1"}
      corTexto={festa.CorTexto ? festa.CorTexto : "#A1A1A1"}
      path={path}
      festa={festa}
      enviarConfirmacao={enviarConfirmacao}
    />
  ) : (
    <NoParty />
  );
};

export default CadastroConvidado;
