import React from "react";
import { useEffect } from "react";
import "./noparty.css";
import Logo from "./marca22-022.png";
import Logo2 from "./logo2.png";

const Agradecimento = () => {
  useEffect(() => {
          changeClass();
          document.title = "PUZLI | Obrigada!"
  }, []);

  const changeClass = () => {
    document.querySelector(".cont_principal").className =
    "cont_principal cont_error_active";
  }

  return (
    <div className="cont_principal">
      <div className="cont_error">
      <h2 className="obrigada">OBRIGADA!</h2>
      </div>
      <div className="logo-puzli"
      style={{background: `url(${Logo}) left top/auto repeat local`}}
      ></div>
        <div className="logo-puzli2"
      style={{background: `url(${Logo2}) left top/auto repeat local`}}
      ></div>

      <div className="cont_aura_1"></div>
      <div className="cont_aura_2"></div>
    </div>
  );
};

export default Agradecimento;
